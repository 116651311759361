"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Character = _interopRequireDefault(require("./Character"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.7.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The CharacterGroup model module.
 * @module model/CharacterGroup
 * @version 2.7.2
 */
class CharacterGroup {
  /**
   * Constructs a new <code>CharacterGroup</code>.
   * @alias module:model/CharacterGroup
   * @param playerId {Number} 
   * @param characters {Array.<module:model/Character>} 
   */
  constructor(playerId, characters) {
    CharacterGroup.initialize(this, playerId, characters);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, playerId, characters) {
    obj['player_id'] = playerId;
    obj['characters'] = characters;
  }

  /**
   * Constructs a <code>CharacterGroup</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CharacterGroup} obj Optional instance to populate.
   * @return {module:model/CharacterGroup} The populated <code>CharacterGroup</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CharacterGroup();
      if (data.hasOwnProperty('player_id')) {
        obj['player_id'] = _ApiClient.default.convertToType(data['player_id'], 'Number');
      }
      if (data.hasOwnProperty('characters')) {
        obj['characters'] = _ApiClient.default.convertToType(data['characters'], [_Character.default]);
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>CharacterGroup</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>CharacterGroup</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of CharacterGroup.RequiredProperties) {
      if (!data.hasOwnProperty(property)) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    if (data['characters']) {
      // data not null
      // ensure the json data is an array
      if (!Array.isArray(data['characters'])) {
        throw new Error("Expected the field `characters` to be an array in the JSON data but got " + data['characters']);
      }
      // validate the optional field `characters` (array)
      for (const item of data['characters']) {
        _Character.default.validateJSON(item);
      }
      ;
    }
    return true;
  }
}
CharacterGroup.RequiredProperties = ["player_id", "characters"];

/**
 * @member {Number} player_id
 */
CharacterGroup.prototype['player_id'] = undefined;

/**
 * @member {Array.<module:model/Character>} characters
 */
CharacterGroup.prototype['characters'] = undefined;
var _default = exports.default = CharacterGroup;