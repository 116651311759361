"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Alliance = _interopRequireDefault(require("../model/Alliance"));
var _Corporation = _interopRequireDefault(require("../model/Corporation"));
var _Group = _interopRequireDefault(require("../model/Group"));
var _GroupApplication = _interopRequireDefault(require("../model/GroupApplication"));
var _Player = _interopRequireDefault(require("../model/Player"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.7.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Group service.
* @module api/GroupApi
* @version 2.7.2
*/
class GroupApi {
  /**
  * Constructs a new GroupApi. 
  * @alias module:api/GroupApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the acceptApplication operation.
   * @callback module:api/GroupApi~acceptApplicationCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Accept a player's request to join a group.
   * Needs role: group-manager
   * @param {Number} id ID of the application.
   * @param {module:api/GroupApi~acceptApplicationCallback} callback The callback function, accepting three arguments: error, data, response
   */
  acceptApplication(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling acceptApplication");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/group/accept-application/{id}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the addMember operation.
   * @callback module:api/GroupApi~addMemberCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Adds a player to a group.
   * Needs role: group-manager or user-manager
   * @param {Number} id ID of the group.
   * @param {Number} pid ID of the player.
   * @param {module:api/GroupApi~addMemberCallback} callback The callback function, accepting three arguments: error, data, response
   */
  addMember(id, pid, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling addMember");
    }
    // verify the required parameter 'pid' is set
    if (pid === undefined || pid === null) {
      throw new Error("Missing the required parameter 'pid' when calling addMember");
    }
    let pathParams = {
      'id': id,
      'pid': pid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/group/{id}/add-member/{pid}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the addRequiredGroup operation.
   * @callback module:api/GroupApi~addRequiredGroupCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add required group to a group.
   * Needs role: group-admin
   * @param {Number} id ID of the group.
   * @param {Number} groupId ID of the group to add.
   * @param {module:api/GroupApi~addRequiredGroupCallback} callback The callback function, accepting three arguments: error, data, response
   */
  addRequiredGroup(id, groupId, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling addRequiredGroup");
    }
    // verify the required parameter 'groupId' is set
    if (groupId === undefined || groupId === null) {
      throw new Error("Missing the required parameter 'groupId' when calling addRequiredGroup");
    }
    let pathParams = {
      'id': id,
      'groupId': groupId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/group/{id}/add-required/{groupId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the alliances operation.
   * @callback module:api/GroupApi~alliancesCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Alliance>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all alliances of a group.
   * Needs role: group-admin
   * @param {Number} id Group ID.
   * @param {module:api/GroupApi~alliancesCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Alliance>}
   */
  alliances(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling alliances");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Alliance.default];
    return this.apiClient.callApi('/user/group/{id}/alliances', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the applications operation.
   * @callback module:api/GroupApi~applicationsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/GroupApplication>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all applications of a group.
   * Needs role: group-manager
   * @param {Number} id Group ID.
   * @param {module:api/GroupApi~applicationsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/GroupApplication>}
   */
  applications(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling applications");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_GroupApplication.default];
    return this.apiClient.callApi('/user/group/{id}/applications', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the corporations operation.
   * @callback module:api/GroupApi~corporationsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Corporation>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all corporations of a group.
   * Needs role: group-admin
   * @param {Number} id Group ID.
   * @param {module:api/GroupApi~corporationsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Corporation>}
   */
  corporations(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling corporations");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Corporation.default];
    return this.apiClient.callApi('/user/group/{id}/corporations', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the denyApplication operation.
   * @callback module:api/GroupApi~denyApplicationCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Deny a player's request to join a group.
   * Needs role: group-manager
   * @param {Number} id ID of the application.
   * @param {module:api/GroupApi~denyApplicationCallback} callback The callback function, accepting three arguments: error, data, response
   */
  denyApplication(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling denyApplication");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/group/deny-application/{id}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removeMember operation.
   * @callback module:api/GroupApi~removeMemberCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove player from a group.
   * Needs role: group-manager or user-manager
   * @param {Number} id ID of the group.
   * @param {Number} pid ID of the player.
   * @param {module:api/GroupApi~removeMemberCallback} callback The callback function, accepting three arguments: error, data, response
   */
  removeMember(id, pid, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling removeMember");
    }
    // verify the required parameter 'pid' is set
    if (pid === undefined || pid === null) {
      throw new Error("Missing the required parameter 'pid' when calling removeMember");
    }
    let pathParams = {
      'id': id,
      'pid': pid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/group/{id}/remove-member/{pid}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removeRequiredGroup operation.
   * @callback module:api/GroupApi~removeRequiredGroupCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove required group from a group.
   * Needs role: group-admin
   * @param {Number} id ID of the group.
   * @param {Number} groupId ID of the group to remove.
   * @param {module:api/GroupApi~removeRequiredGroupCallback} callback The callback function, accepting three arguments: error, data, response
   */
  removeRequiredGroup(id, groupId, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling removeRequiredGroup");
    }
    // verify the required parameter 'groupId' is set
    if (groupId === undefined || groupId === null) {
      throw new Error("Missing the required parameter 'groupId' when calling removeRequiredGroup");
    }
    let pathParams = {
      'id': id,
      'groupId': groupId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/group/{id}/remove-required/{groupId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the requiredGroups operation.
   * @callback module:api/GroupApi~requiredGroupsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Group>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all required groups of a group.
   * Needs role: group-admin, group-manager
   * @param {Number} id Group ID.
   * @param {module:api/GroupApi~requiredGroupsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Group>}
   */
  requiredGroups(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling requiredGroups");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Group.default];
    return this.apiClient.callApi('/user/group/{id}/required-groups', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the setVisibility operation.
   * @callback module:api/GroupApi~setVisibilityCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Group} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Change visibility of a group.
   * Needs role: group-admin
   * @param {Number} id ID of the group.
   * @param {module:model/String} choice 
   * @param {module:api/GroupApi~setVisibilityCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Group}
   */
  setVisibility(id, choice, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling setVisibility");
    }
    // verify the required parameter 'choice' is set
    if (choice === undefined || choice === null) {
      throw new Error("Missing the required parameter 'choice' when calling setVisibility");
    }
    let pathParams = {
      'id': id,
      'choice': choice
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Group.default;
    return this.apiClient.callApi('/user/group/{id}/set-visibility/{choice}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userGroupAddForbiddenGroup operation.
   * @callback module:api/GroupApi~userGroupAddForbiddenGroupCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add forbidden group to a group.
   * Needs role: group-admin
   * @param {Number} id ID of the group.
   * @param {Number} groupId ID of the group to add.
   * @param {module:api/GroupApi~userGroupAddForbiddenGroupCallback} callback The callback function, accepting three arguments: error, data, response
   */
  userGroupAddForbiddenGroup(id, groupId, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userGroupAddForbiddenGroup");
    }
    // verify the required parameter 'groupId' is set
    if (groupId === undefined || groupId === null) {
      throw new Error("Missing the required parameter 'groupId' when calling userGroupAddForbiddenGroup");
    }
    let pathParams = {
      'id': id,
      'groupId': groupId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/group/{id}/add-forbidden/{groupId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userGroupAddManager operation.
   * @callback module:api/GroupApi~userGroupAddManagerCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Assign a player as manager to a group.
   * Needs role: group-admin
   * @param {Number} id ID of the group.
   * @param {Number} pid ID of the player.
   * @param {module:api/GroupApi~userGroupAddManagerCallback} callback The callback function, accepting three arguments: error, data, response
   */
  userGroupAddManager(id, pid, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userGroupAddManager");
    }
    // verify the required parameter 'pid' is set
    if (pid === undefined || pid === null) {
      throw new Error("Missing the required parameter 'pid' when calling userGroupAddManager");
    }
    let pathParams = {
      'id': id,
      'pid': pid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/group/{id}/add-manager/{pid}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userGroupAll operation.
   * @callback module:api/GroupApi~userGroupAllCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Group>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all groups.
   * Needs role: app-admin, group-admin, watchlist-admin, plugin-admin or user-manager
   * @param {module:api/GroupApi~userGroupAllCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Group>}
   */
  userGroupAll(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Group.default];
    return this.apiClient.callApi('/user/group/all', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userGroupCreate operation.
   * @callback module:api/GroupApi~userGroupCreateCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Group} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Create a group.
   * Needs role: group-admin
   * @param {String} name Name of the group.
   * @param {module:api/GroupApi~userGroupCreateCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Group}
   */
  userGroupCreate(name, callback) {
    let postBody = null;
    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling userGroupCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'name': name
    };
    let authNames = ['CSRF', 'Session'];
    let contentTypes = ['application/x-www-form-urlencoded'];
    let accepts = ['application/json'];
    let returnType = _Group.default;
    return this.apiClient.callApi('/user/group/create', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userGroupDelete operation.
   * @callback module:api/GroupApi~userGroupDeleteCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Delete a group.
   * Needs role: group-admin
   * @param {Number} id ID of the group.
   * @param {module:api/GroupApi~userGroupDeleteCallback} callback The callback function, accepting three arguments: error, data, response
   */
  userGroupDelete(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userGroupDelete");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/group/{id}/delete', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userGroupForbiddenGroups operation.
   * @callback module:api/GroupApi~userGroupForbiddenGroupsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Group>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all forbidden groups of a group.
   * Needs role: group-admin, group-manager
   * @param {Number} id Group ID.
   * @param {module:api/GroupApi~userGroupForbiddenGroupsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Group>}
   */
  userGroupForbiddenGroups(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userGroupForbiddenGroups");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Group.default];
    return this.apiClient.callApi('/user/group/{id}/forbidden-groups', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userGroupManagers operation.
   * @callback module:api/GroupApi~userGroupManagersCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Player>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all managers of a group.
   * Needs role: group-admin, group-manager
   * @param {Number} id Group ID.
   * @param {module:api/GroupApi~userGroupManagersCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Player>}
   */
  userGroupManagers(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userGroupManagers");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Player.default];
    return this.apiClient.callApi('/user/group/{id}/managers', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userGroupMembers operation.
   * @callback module:api/GroupApi~userGroupMembersCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Player>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all members of a group.
   * Needs role: group-admin, group-manager
   * @param {Number} id Group ID.
   * @param {module:api/GroupApi~userGroupMembersCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Player>}
   */
  userGroupMembers(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userGroupMembers");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Player.default];
    return this.apiClient.callApi('/user/group/{id}/members', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userGroupPublic operation.
   * @callback module:api/GroupApi~userGroupPublicCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Group>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all public groups that the player can join.
   * Needs role: user
   * @param {module:api/GroupApi~userGroupPublicCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Group>}
   */
  userGroupPublic(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Group.default];
    return this.apiClient.callApi('/user/group/public', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userGroupRemoveForbiddenGroup operation.
   * @callback module:api/GroupApi~userGroupRemoveForbiddenGroupCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove forbidden group from a group.
   * Needs role: group-admin
   * @param {Number} id ID of the group.
   * @param {Number} groupId ID of the group to remove.
   * @param {module:api/GroupApi~userGroupRemoveForbiddenGroupCallback} callback The callback function, accepting three arguments: error, data, response
   */
  userGroupRemoveForbiddenGroup(id, groupId, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userGroupRemoveForbiddenGroup");
    }
    // verify the required parameter 'groupId' is set
    if (groupId === undefined || groupId === null) {
      throw new Error("Missing the required parameter 'groupId' when calling userGroupRemoveForbiddenGroup");
    }
    let pathParams = {
      'id': id,
      'groupId': groupId
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/group/{id}/remove-forbidden/{groupId}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userGroupRemoveManager operation.
   * @callback module:api/GroupApi~userGroupRemoveManagerCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove a manager (player) from a group.
   * Needs role: group-admin
   * @param {Number} id ID of the group.
   * @param {Number} pid ID of the player.
   * @param {module:api/GroupApi~userGroupRemoveManagerCallback} callback The callback function, accepting three arguments: error, data, response
   */
  userGroupRemoveManager(id, pid, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userGroupRemoveManager");
    }
    // verify the required parameter 'pid' is set
    if (pid === undefined || pid === null) {
      throw new Error("Missing the required parameter 'pid' when calling userGroupRemoveManager");
    }
    let pathParams = {
      'id': id,
      'pid': pid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/group/{id}/remove-manager/{pid}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userGroupRename operation.
   * @callback module:api/GroupApi~userGroupRenameCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Group} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Rename a group.
   * Needs role: group-admin
   * @param {Number} id ID of the group.
   * @param {String} name New name for the group.
   * @param {module:api/GroupApi~userGroupRenameCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Group}
   */
  userGroupRename(id, name, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userGroupRename");
    }
    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling userGroupRename");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'name': name
    };
    let authNames = ['CSRF', 'Session'];
    let contentTypes = ['application/x-www-form-urlencoded'];
    let accepts = ['application/json'];
    let returnType = _Group.default;
    return this.apiClient.callApi('/user/group/{id}/rename', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userGroupSetAutoAccept operation.
   * @callback module:api/GroupApi~userGroupSetAutoAcceptCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Group} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Change the auto-accept setting of a group.
   * Needs role: group-admin
   * @param {Number} id ID of the group.
   * @param {module:model/String} choice 
   * @param {module:api/GroupApi~userGroupSetAutoAcceptCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Group}
   */
  userGroupSetAutoAccept(id, choice, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userGroupSetAutoAccept");
    }
    // verify the required parameter 'choice' is set
    if (choice === undefined || choice === null) {
      throw new Error("Missing the required parameter 'choice' when calling userGroupSetAutoAccept");
    }
    let pathParams = {
      'id': id,
      'choice': choice
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Group.default;
    return this.apiClient.callApi('/user/group/{id}/set-auto-accept/{choice}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userGroupSetIsDefault operation.
   * @callback module:api/GroupApi~userGroupSetIsDefaultCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Group} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Change the is-default setting of a group.
   * Needs role: group-admin
   * @param {Number} id ID of the group.
   * @param {module:model/String} choice 
   * @param {module:api/GroupApi~userGroupSetIsDefaultCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Group}
   */
  userGroupSetIsDefault(id, choice, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userGroupSetIsDefault");
    }
    // verify the required parameter 'choice' is set
    if (choice === undefined || choice === null) {
      throw new Error("Missing the required parameter 'choice' when calling userGroupSetIsDefault");
    }
    let pathParams = {
      'id': id,
      'choice': choice
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Group.default;
    return this.apiClient.callApi('/user/group/{id}/set-is-default/{choice}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userGroupUpdateDescription operation.
   * @callback module:api/GroupApi~userGroupUpdateDescriptionCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Group} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Update group description.
   * Needs role: group-admin
   * @param {Number} id ID of the group.
   * @param {String} description The description for the group.
   * @param {module:api/GroupApi~userGroupUpdateDescriptionCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Group}
   */
  userGroupUpdateDescription(id, description, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userGroupUpdateDescription");
    }
    // verify the required parameter 'description' is set
    if (description === undefined || description === null) {
      throw new Error("Missing the required parameter 'description' when calling userGroupUpdateDescription");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'description': description
    };
    let authNames = ['CSRF', 'Session'];
    let contentTypes = ['application/x-www-form-urlencoded'];
    let accepts = ['application/json'];
    let returnType = _Group.default;
    return this.apiClient.callApi('/user/group/{id}/update-description', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = GroupApi;