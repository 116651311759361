"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Alliance = _interopRequireDefault(require("../model/Alliance"));
var _Corporation = _interopRequireDefault(require("../model/Corporation"));
var _Group = _interopRequireDefault(require("../model/Group"));
var _Player = _interopRequireDefault(require("../model/Player"));
var _Watchlist = _interopRequireDefault(require("../model/Watchlist"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.7.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Watchlist service.
* @module api/WatchlistApi
* @version 2.7.2
*/
class WatchlistApi {
  /**
  * Constructs a new WatchlistApi. 
  * @alias module:api/WatchlistApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the watchlistAllianceAdd operation.
   * @callback module:api/WatchlistApi~watchlistAllianceAddCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add alliance to the list.
   * Needs role: watchlist-manager, watchlist-admin
   * @param {Number} id Watchlist ID.
   * @param {Number} alliance Alliance ID.
   * @param {module:api/WatchlistApi~watchlistAllianceAddCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistAllianceAdd(id, alliance, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistAllianceAdd");
    }
    // verify the required parameter 'alliance' is set
    if (alliance === undefined || alliance === null) {
      throw new Error("Missing the required parameter 'alliance' when calling watchlistAllianceAdd");
    }
    let pathParams = {
      'id': id,
      'alliance': alliance
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/alliance/add/{alliance}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistAllianceList operation.
   * @callback module:api/WatchlistApi~watchlistAllianceListCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Alliance>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List of alliances for this list.
   * Needs role: watchlist, watchlist-manager, watchlist-admin
   * @param {Number} id Watchlist ID.
   * @param {module:api/WatchlistApi~watchlistAllianceListCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Alliance>}
   */
  watchlistAllianceList(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistAllianceList");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Alliance.default];
    return this.apiClient.callApi('/user/watchlist/{id}/alliance/list', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistAllianceRemove operation.
   * @callback module:api/WatchlistApi~watchlistAllianceRemoveCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove alliance from the list.
   * Needs role: watchlist-manager, watchlist-admin
   * @param {Number} id Watchlist ID.
   * @param {Number} alliance Alliance ID.
   * @param {module:api/WatchlistApi~watchlistAllianceRemoveCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistAllianceRemove(id, alliance, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistAllianceRemove");
    }
    // verify the required parameter 'alliance' is set
    if (alliance === undefined || alliance === null) {
      throw new Error("Missing the required parameter 'alliance' when calling watchlistAllianceRemove");
    }
    let pathParams = {
      'id': id,
      'alliance': alliance
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/alliance/remove/{alliance}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistAllowlistAllianceAdd operation.
   * @callback module:api/WatchlistApi~watchlistAllowlistAllianceAddCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add alliance to the alliance allowlist.
   * Needs role: watchlist-manager
   * @param {Number} id Watchlist ID.
   * @param {Number} alliance Alliance ID.
   * @param {module:api/WatchlistApi~watchlistAllowlistAllianceAddCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistAllowlistAllianceAdd(id, alliance, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistAllowlistAllianceAdd");
    }
    // verify the required parameter 'alliance' is set
    if (alliance === undefined || alliance === null) {
      throw new Error("Missing the required parameter 'alliance' when calling watchlistAllowlistAllianceAdd");
    }
    let pathParams = {
      'id': id,
      'alliance': alliance
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/allowlist-alliance/add/{alliance}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistAllowlistAllianceList operation.
   * @callback module:api/WatchlistApi~watchlistAllowlistAllianceListCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Alliance>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List of alliances for the alliance allowlist.
   * Needs role: watchlist, watchlist-manager
   * @param {Number} id Watchlist ID.
   * @param {module:api/WatchlistApi~watchlistAllowlistAllianceListCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Alliance>}
   */
  watchlistAllowlistAllianceList(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistAllowlistAllianceList");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Alliance.default];
    return this.apiClient.callApi('/user/watchlist/{id}/allowlist-alliance/list', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistAllowlistAllianceRemove operation.
   * @callback module:api/WatchlistApi~watchlistAllowlistAllianceRemoveCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove alliance from the alliance allowlist.
   * Needs role: watchlist-manager
   * @param {Number} id Watchlist ID.
   * @param {Number} alliance Alliance ID.
   * @param {module:api/WatchlistApi~watchlistAllowlistAllianceRemoveCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistAllowlistAllianceRemove(id, alliance, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistAllowlistAllianceRemove");
    }
    // verify the required parameter 'alliance' is set
    if (alliance === undefined || alliance === null) {
      throw new Error("Missing the required parameter 'alliance' when calling watchlistAllowlistAllianceRemove");
    }
    let pathParams = {
      'id': id,
      'alliance': alliance
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/allowlist-alliance/remove/{alliance}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistAllowlistCorporationAdd operation.
   * @callback module:api/WatchlistApi~watchlistAllowlistCorporationAddCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add corporation to the corporation allowlist.
   * Needs role: watchlist-manager
   * @param {Number} id Watchlist ID.
   * @param {Number} corporation Corporation ID.
   * @param {module:api/WatchlistApi~watchlistAllowlistCorporationAddCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistAllowlistCorporationAdd(id, corporation, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistAllowlistCorporationAdd");
    }
    // verify the required parameter 'corporation' is set
    if (corporation === undefined || corporation === null) {
      throw new Error("Missing the required parameter 'corporation' when calling watchlistAllowlistCorporationAdd");
    }
    let pathParams = {
      'id': id,
      'corporation': corporation
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/allowlist-corporation/add/{corporation}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistAllowlistCorporationList operation.
   * @callback module:api/WatchlistApi~watchlistAllowlistCorporationListCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Corporation>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List of corporations for the corporation allowlist.
   * Needs role: watchlist, watchlist-manager
   * @param {Number} id Watchlist ID.
   * @param {module:api/WatchlistApi~watchlistAllowlistCorporationListCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Corporation>}
   */
  watchlistAllowlistCorporationList(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistAllowlistCorporationList");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Corporation.default];
    return this.apiClient.callApi('/user/watchlist/{id}/allowlist-corporation/list', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistAllowlistCorporationRemove operation.
   * @callback module:api/WatchlistApi~watchlistAllowlistCorporationRemoveCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove corporation from the corporation allowlist.
   * Needs role: watchlist-manager
   * @param {Number} id Watchlist ID.
   * @param {Number} corporation Corporation ID.
   * @param {module:api/WatchlistApi~watchlistAllowlistCorporationRemoveCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistAllowlistCorporationRemove(id, corporation, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistAllowlistCorporationRemove");
    }
    // verify the required parameter 'corporation' is set
    if (corporation === undefined || corporation === null) {
      throw new Error("Missing the required parameter 'corporation' when calling watchlistAllowlistCorporationRemove");
    }
    let pathParams = {
      'id': id,
      'corporation': corporation
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/allowlist-corporation/remove/{corporation}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistCorporationAdd operation.
   * @callback module:api/WatchlistApi~watchlistCorporationAddCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add corporation to the list.
   * Needs role: watchlist-manager, watchlist-admin
   * @param {Number} id Watchlist ID.
   * @param {Number} corporation Corporation ID.
   * @param {module:api/WatchlistApi~watchlistCorporationAddCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistCorporationAdd(id, corporation, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistCorporationAdd");
    }
    // verify the required parameter 'corporation' is set
    if (corporation === undefined || corporation === null) {
      throw new Error("Missing the required parameter 'corporation' when calling watchlistCorporationAdd");
    }
    let pathParams = {
      'id': id,
      'corporation': corporation
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/corporation/add/{corporation}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistCorporationList operation.
   * @callback module:api/WatchlistApi~watchlistCorporationListCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Corporation>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List of corporations for this list.
   * Needs role: watchlist, watchlist-manager, watchlist-admin
   * @param {Number} id Watchlist ID.
   * @param {module:api/WatchlistApi~watchlistCorporationListCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Corporation>}
   */
  watchlistCorporationList(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistCorporationList");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Corporation.default];
    return this.apiClient.callApi('/user/watchlist/{id}/corporation/list', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistCorporationRemove operation.
   * @callback module:api/WatchlistApi~watchlistCorporationRemoveCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove corporation from the list.
   * Needs role: watchlist-manager, watchlist-admin
   * @param {Number} id Watchlist ID.
   * @param {Number} corporation Corporation ID.
   * @param {module:api/WatchlistApi~watchlistCorporationRemoveCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistCorporationRemove(id, corporation, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistCorporationRemove");
    }
    // verify the required parameter 'corporation' is set
    if (corporation === undefined || corporation === null) {
      throw new Error("Missing the required parameter 'corporation' when calling watchlistCorporationRemove");
    }
    let pathParams = {
      'id': id,
      'corporation': corporation
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/corporation/remove/{corporation}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistCreate operation.
   * @callback module:api/WatchlistApi~watchlistCreateCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Watchlist} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Create a watchlist.
   * Needs role: watchlist-admin
   * @param {String} name Name of the watchlist.
   * @param {module:api/WatchlistApi~watchlistCreateCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Watchlist}
   */
  watchlistCreate(name, callback) {
    let postBody = null;
    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling watchlistCreate");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'name': name
    };
    let authNames = ['CSRF', 'Session'];
    let contentTypes = ['application/x-www-form-urlencoded'];
    let accepts = ['application/json'];
    let returnType = _Watchlist.default;
    return this.apiClient.callApi('/user/watchlist/create', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistDelete operation.
   * @callback module:api/WatchlistApi~watchlistDeleteCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Delete a watchlist.
   * Needs role: watchlist-admin
   * @param {Number} id ID of the watchlist.
   * @param {module:api/WatchlistApi~watchlistDeleteCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistDelete(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistDelete");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/delete', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistExemptionAdd operation.
   * @callback module:api/WatchlistApi~watchlistExemptionAddCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add player to exemption list.
   * Needs role: watchlist-manager
   * @param {Number} id Watchlist ID.
   * @param {Number} player Player ID.
   * @param {module:api/WatchlistApi~watchlistExemptionAddCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistExemptionAdd(id, player, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistExemptionAdd");
    }
    // verify the required parameter 'player' is set
    if (player === undefined || player === null) {
      throw new Error("Missing the required parameter 'player' when calling watchlistExemptionAdd");
    }
    let pathParams = {
      'id': id,
      'player': player
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/exemption/add/{player}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistExemptionList operation.
   * @callback module:api/WatchlistApi~watchlistExemptionListCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Player>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List of exempt players.
   * Needs role: watchlist, watchlist-manager
   * @param {Number} id Watchlist ID.
   * @param {module:api/WatchlistApi~watchlistExemptionListCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Player>}
   */
  watchlistExemptionList(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistExemptionList");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Player.default];
    return this.apiClient.callApi('/user/watchlist/{id}/exemption/list', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistExemptionRemove operation.
   * @callback module:api/WatchlistApi~watchlistExemptionRemoveCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove player from exemption list.
   * Needs role: watchlist-manager
   * @param {Number} id Watchlist ID.
   * @param {Number} player Player ID.
   * @param {module:api/WatchlistApi~watchlistExemptionRemoveCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistExemptionRemove(id, player, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistExemptionRemove");
    }
    // verify the required parameter 'player' is set
    if (player === undefined || player === null) {
      throw new Error("Missing the required parameter 'player' when calling watchlistExemptionRemove");
    }
    let pathParams = {
      'id': id,
      'player': player
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/exemption/remove/{player}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistGroupAdd operation.
   * @callback module:api/WatchlistApi~watchlistGroupAddCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add access group to the list.
   * Needs role: watchlist-admin
   * @param {Number} id Watchlist ID.
   * @param {Number} group Group ID.
   * @param {module:api/WatchlistApi~watchlistGroupAddCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistGroupAdd(id, group, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistGroupAdd");
    }
    // verify the required parameter 'group' is set
    if (group === undefined || group === null) {
      throw new Error("Missing the required parameter 'group' when calling watchlistGroupAdd");
    }
    let pathParams = {
      'id': id,
      'group': group
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/group/add/{group}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistGroupList operation.
   * @callback module:api/WatchlistApi~watchlistGroupListCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Group>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List of groups with access to this list.
   * Needs role: watchlist-admin
   * @param {Number} id Watchlist ID.
   * @param {module:api/WatchlistApi~watchlistGroupListCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Group>}
   */
  watchlistGroupList(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistGroupList");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Group.default];
    return this.apiClient.callApi('/user/watchlist/{id}/group/list', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistGroupRemove operation.
   * @callback module:api/WatchlistApi~watchlistGroupRemoveCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove access group from the list.
   * Needs role: watchlist-admin
   * @param {Number} id Watchlist ID.
   * @param {Number} group Group ID.
   * @param {module:api/WatchlistApi~watchlistGroupRemoveCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistGroupRemove(id, group, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistGroupRemove");
    }
    // verify the required parameter 'group' is set
    if (group === undefined || group === null) {
      throw new Error("Missing the required parameter 'group' when calling watchlistGroupRemove");
    }
    let pathParams = {
      'id': id,
      'group': group
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/group/remove/{group}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistKicklistAllianceAdd operation.
   * @callback module:api/WatchlistApi~watchlistKicklistAllianceAddCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add alliance to the kicklist.
   * Needs role: watchlist-manager
   * @param {Number} id Watchlist ID.
   * @param {Number} alliance Alliance ID.
   * @param {module:api/WatchlistApi~watchlistKicklistAllianceAddCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistKicklistAllianceAdd(id, alliance, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistKicklistAllianceAdd");
    }
    // verify the required parameter 'alliance' is set
    if (alliance === undefined || alliance === null) {
      throw new Error("Missing the required parameter 'alliance' when calling watchlistKicklistAllianceAdd");
    }
    let pathParams = {
      'id': id,
      'alliance': alliance
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/kicklist-alliance/add/{alliance}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistKicklistAllianceList operation.
   * @callback module:api/WatchlistApi~watchlistKicklistAllianceListCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Alliance>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List of alliances for the kicklist.
   * Needs role: watchlist, watchlist-manager
   * @param {Number} id Watchlist ID.
   * @param {module:api/WatchlistApi~watchlistKicklistAllianceListCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Alliance>}
   */
  watchlistKicklistAllianceList(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistKicklistAllianceList");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Alliance.default];
    return this.apiClient.callApi('/user/watchlist/{id}/kicklist-alliance/list', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistKicklistAllianceRemove operation.
   * @callback module:api/WatchlistApi~watchlistKicklistAllianceRemoveCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove alliance from the kicklist.
   * Needs role: watchlist-manager
   * @param {Number} id Watchlist ID.
   * @param {Number} alliance Alliance ID.
   * @param {module:api/WatchlistApi~watchlistKicklistAllianceRemoveCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistKicklistAllianceRemove(id, alliance, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistKicklistAllianceRemove");
    }
    // verify the required parameter 'alliance' is set
    if (alliance === undefined || alliance === null) {
      throw new Error("Missing the required parameter 'alliance' when calling watchlistKicklistAllianceRemove");
    }
    let pathParams = {
      'id': id,
      'alliance': alliance
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/kicklist-alliance/remove/{alliance}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistKicklistCorporationAdd operation.
   * @callback module:api/WatchlistApi~watchlistKicklistCorporationAddCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add corporation to the kicklist.
   * Needs role: watchlist-manager
   * @param {Number} id Watchlist ID.
   * @param {Number} corporation Corporation ID.
   * @param {module:api/WatchlistApi~watchlistKicklistCorporationAddCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistKicklistCorporationAdd(id, corporation, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistKicklistCorporationAdd");
    }
    // verify the required parameter 'corporation' is set
    if (corporation === undefined || corporation === null) {
      throw new Error("Missing the required parameter 'corporation' when calling watchlistKicklistCorporationAdd");
    }
    let pathParams = {
      'id': id,
      'corporation': corporation
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/kicklist-corporation/add/{corporation}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistKicklistCorporationList operation.
   * @callback module:api/WatchlistApi~watchlistKicklistCorporationListCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Corporation>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List of corporations for the kicklist.
   * Needs role: watchlist, watchlist-manager
   * @param {Number} id Watchlist ID.
   * @param {module:api/WatchlistApi~watchlistKicklistCorporationListCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Corporation>}
   */
  watchlistKicklistCorporationList(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistKicklistCorporationList");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Corporation.default];
    return this.apiClient.callApi('/user/watchlist/{id}/kicklist-corporation/list', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistKicklistCorporationRemove operation.
   * @callback module:api/WatchlistApi~watchlistKicklistCorporationRemoveCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove corporation from the kicklist.
   * Needs role: watchlist-manager
   * @param {Number} id Watchlist ID.
   * @param {Number} corporation Corporation ID.
   * @param {module:api/WatchlistApi~watchlistKicklistCorporationRemoveCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistKicklistCorporationRemove(id, corporation, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistKicklistCorporationRemove");
    }
    // verify the required parameter 'corporation' is set
    if (corporation === undefined || corporation === null) {
      throw new Error("Missing the required parameter 'corporation' when calling watchlistKicklistCorporationRemove");
    }
    let pathParams = {
      'id': id,
      'corporation': corporation
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/kicklist-corporation/remove/{corporation}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistListAll operation.
   * @callback module:api/WatchlistApi~watchlistListAllCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Watchlist>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Lists all watchlists.
   * Needs role: watchlist-admin
   * @param {module:api/WatchlistApi~watchlistListAllCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Watchlist>}
   */
  watchlistListAll(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Watchlist.default];
    return this.apiClient.callApi('/user/watchlist/listAll', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistListAvailable operation.
   * @callback module:api/WatchlistApi~watchlistListAvailableCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Watchlist>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Lists all watchlists with view permission.
   * Needs role: watchlist
   * @param {module:api/WatchlistApi~watchlistListAvailableCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Watchlist>}
   */
  watchlistListAvailable(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Watchlist.default];
    return this.apiClient.callApi('/user/watchlist/list-available', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistListAvailableManage operation.
   * @callback module:api/WatchlistApi~watchlistListAvailableManageCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Watchlist>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Lists all watchlists with manage permission.
   * Needs role: watchlist-manager
   * @param {module:api/WatchlistApi~watchlistListAvailableManageCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Watchlist>}
   */
  watchlistListAvailableManage(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Watchlist.default];
    return this.apiClient.callApi('/user/watchlist/list-available-manage', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistLockWatchlistSettings operation.
   * @callback module:api/WatchlistApi~watchlistLockWatchlistSettingsCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Watchlist} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Lock or unlock the watchlist settings.
   * Needs role: watchlist-admin
   * @param {Number} id ID of the watchlist.
   * @param {module:model/String} lock 
   * @param {module:api/WatchlistApi~watchlistLockWatchlistSettingsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Watchlist}
   */
  watchlistLockWatchlistSettings(id, lock, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistLockWatchlistSettings");
    }
    // verify the required parameter 'lock' is set
    if (lock === undefined || lock === null) {
      throw new Error("Missing the required parameter 'lock' when calling watchlistLockWatchlistSettings");
    }
    let pathParams = {
      'id': id,
      'lock': lock
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Watchlist.default;
    return this.apiClient.callApi('/user/watchlist/{id}/lock-watchlist-settings/{lock}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistManagerGroupAdd operation.
   * @callback module:api/WatchlistApi~watchlistManagerGroupAddCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add manager access group to the list.
   * Needs role: watchlist-admin
   * @param {Number} id Watchlist ID.
   * @param {Number} group Group ID.
   * @param {module:api/WatchlistApi~watchlistManagerGroupAddCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistManagerGroupAdd(id, group, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistManagerGroupAdd");
    }
    // verify the required parameter 'group' is set
    if (group === undefined || group === null) {
      throw new Error("Missing the required parameter 'group' when calling watchlistManagerGroupAdd");
    }
    let pathParams = {
      'id': id,
      'group': group
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/manager-group/add/{group}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistManagerGroupList operation.
   * @callback module:api/WatchlistApi~watchlistManagerGroupListCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Group>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List of groups with manager access to this list.
   * Needs role: watchlist-admin
   * @param {Number} id Watchlist ID.
   * @param {module:api/WatchlistApi~watchlistManagerGroupListCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Group>}
   */
  watchlistManagerGroupList(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistManagerGroupList");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Group.default];
    return this.apiClient.callApi('/user/watchlist/{id}/manager-group/list', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistManagerGroupRemove operation.
   * @callback module:api/WatchlistApi~watchlistManagerGroupRemoveCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove manager access group from the list.
   * Needs role: watchlist-admin
   * @param {Number} id Watchlist ID.
   * @param {Number} group Group ID.
   * @param {module:api/WatchlistApi~watchlistManagerGroupRemoveCallback} callback The callback function, accepting three arguments: error, data, response
   */
  watchlistManagerGroupRemove(id, group, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistManagerGroupRemove");
    }
    // verify the required parameter 'group' is set
    if (group === undefined || group === null) {
      throw new Error("Missing the required parameter 'group' when calling watchlistManagerGroupRemove");
    }
    let pathParams = {
      'id': id,
      'group': group
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/watchlist/{id}/manager-group/remove/{group}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistPlayers operation.
   * @callback module:api/WatchlistApi~watchlistPlayersCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Player>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List of player accounts that have characters in one of the configured alliances or corporations and additionally have other characters in another player (not NPC) corporation that is not on the allowlist and have not been manually excluded.
   * Needs role: watchlist
   * @param {Number} id Watchlist ID.
   * @param {module:api/WatchlistApi~watchlistPlayersCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Player>}
   */
  watchlistPlayers(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistPlayers");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Player.default];
    return this.apiClient.callApi('/user/watchlist/{id}/players', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistPlayersKicklist operation.
   * @callback module:api/WatchlistApi~watchlistPlayersKicklistCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Player>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Accounts from the watchlist with members in one of the alliances or corporations from the kicklist.
   * Needs role: watchlist
   * @param {Number} id Watchlist ID.
   * @param {module:api/WatchlistApi~watchlistPlayersKicklistCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Player>}
   */
  watchlistPlayersKicklist(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistPlayersKicklist");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Player.default];
    return this.apiClient.callApi('/user/watchlist/{id}/players-kicklist', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the watchlistRename operation.
   * @callback module:api/WatchlistApi~watchlistRenameCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Watchlist} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Rename a watchlist.
   * Needs role: watchlist-admin
   * @param {Number} id ID of the watchlist.
   * @param {String} name New name for the watchlist.
   * @param {module:api/WatchlistApi~watchlistRenameCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Watchlist}
   */
  watchlistRename(id, name, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling watchlistRename");
    }
    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling watchlistRename");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'name': name
    };
    let authNames = ['CSRF', 'Session'];
    let contentTypes = ['application/x-www-form-urlencoded'];
    let accepts = ['application/json'];
    let returnType = _Watchlist.default;
    return this.apiClient.callApi('/user/watchlist/{id}/rename', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = WatchlistApi;