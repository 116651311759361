"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Character = _interopRequireDefault(require("../model/Character"));
var _CharacterGroup = _interopRequireDefault(require("../model/CharacterGroup"));
var _GroupApplication = _interopRequireDefault(require("../model/GroupApplication"));
var _GroupsDisabled = _interopRequireDefault(require("../model/GroupsDisabled"));
var _Player = _interopRequireDefault(require("../model/Player"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.7.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
* Player service.
* @module api/PlayerApi
* @version 2.7.2
*/
class PlayerApi {
  /**
  * Constructs a new PlayerApi. 
  * @alias module:api/PlayerApi
  * @class
  * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
  * default to {@link module:ApiClient#instance} if unspecified.
  */
  constructor(apiClient) {
    this.apiClient = apiClient || _ApiClient.default.instance;
  }

  /**
   * Callback function to receive the result of the addApplication operation.
   * @callback module:api/PlayerApi~addApplicationCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Submit a group application.
   * Needs role: user
   * @param {Number} gid ID of the group.
   * @param {module:api/PlayerApi~addApplicationCallback} callback The callback function, accepting three arguments: error, data, response
   */
  addApplication(gid, callback) {
    let postBody = null;
    // verify the required parameter 'gid' is set
    if (gid === undefined || gid === null) {
      throw new Error("Missing the required parameter 'gid' when calling addApplication");
    }
    let pathParams = {
      'gid': gid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/player/add-application/{gid}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the appManagers operation.
   * @callback module:api/PlayerApi~appManagersCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Player>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all players with the role app-manger.
   * Needs role: app-admin
   * @param {module:api/PlayerApi~appManagersCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Player>}
   */
  appManagers(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Player.default];
    return this.apiClient.callApi('/user/player/app-managers', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the deleteCharacter operation.
   * @callback module:api/PlayerApi~deleteCharacterCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Delete a character.
   * Needs role: user, user-admin
   * @param {Number} id ID of the character.
   * @param {Object} opts Optional parameters
   * @param {module:model/String} [adminReason] Specifies a reason if a user admin triggered the deletion. ('deleted-by-admin' will not create a 'Removed Character' entry.)
   * @param {module:api/PlayerApi~deleteCharacterCallback} callback The callback function, accepting three arguments: error, data, response
   */
  deleteCharacter(id, opts, callback) {
    opts = opts || {};
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling deleteCharacter");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {
      'admin-reason': opts['adminReason']
    };
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/player/delete-character/{id}', 'DELETE', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the groupManagers operation.
   * @callback module:api/PlayerApi~groupManagersCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Player>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all players with the role group-manger.
   * Needs role: group-admin
   * @param {module:api/PlayerApi~groupManagersCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Player>}
   */
  groupManagers(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Player.default];
    return this.apiClient.callApi('/user/player/group-managers', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the groupsDisabled operation.
   * @callback module:api/PlayerApi~groupsDisabledCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GroupsDisabled} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Checks whether groups for this account are disabled or will be disabled soon.
   * Needs role: user
   * @param {module:api/PlayerApi~groupsDisabledCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GroupsDisabled}
   */
  groupsDisabled(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GroupsDisabled.default;
    return this.apiClient.callApi('/user/player/groups-disabled', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the groupsDisabledById operation.
   * @callback module:api/PlayerApi~groupsDisabledByIdCallback
   * @param {String} error Error message, if any.
   * @param {module:model/GroupsDisabled} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Checks whether groups for this account are disabled or will be disabled soon.
   * Needs role: user-admin or the same permissions as for the 'userPlayerCharacters' endpoint.
   * @param {Number} id ID of the player.
   * @param {module:api/PlayerApi~groupsDisabledByIdCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/GroupsDisabled}
   */
  groupsDisabledById(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling groupsDisabledById");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _GroupsDisabled.default;
    return this.apiClient.callApi('/user/player/{id}/groups-disabled', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the leaveGroup operation.
   * @callback module:api/PlayerApi~leaveGroupCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Leave a group.
   * Needs role: user
   * @param {Number} gid ID of the group.
   * @param {module:api/PlayerApi~leaveGroupCallback} callback The callback function, accepting three arguments: error, data, response
   */
  leaveGroup(gid, callback) {
    let postBody = null;
    // verify the required parameter 'gid' is set
    if (gid === undefined || gid === null) {
      throw new Error("Missing the required parameter 'gid' when calling leaveGroup");
    }
    let pathParams = {
      'gid': gid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/player/leave-group/{gid}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the playerGroupCharactersByAccount operation.
   * @callback module:api/PlayerApi~playerGroupCharactersByAccountCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/CharacterGroup>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Accepts a list of character names and returns them grouped by account.
   * Needs role: user-chars.<br>The returned character list always contains the main character as the first character in the list. Characters that do not exist will all be added to a separate group as the last element of the result list.
   * @param {String} body List of character names, one per line.
   * @param {module:api/PlayerApi~playerGroupCharactersByAccountCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/CharacterGroup>}
   */
  playerGroupCharactersByAccount(body, callback) {
    let postBody = body;
    // verify the required parameter 'body' is set
    if (body === undefined || body === null) {
      throw new Error("Missing the required parameter 'body' when calling playerGroupCharactersByAccount");
    }
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = ['text/plain'];
    let accepts = ['application/json'];
    let returnType = [_CharacterGroup.default];
    return this.apiClient.callApi('/user/player/group-characters-by-account', 'POST', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the removeApplication operation.
   * @callback module:api/PlayerApi~removeApplicationCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Cancel a group application.
   * Needs role: user
   * @param {Number} gid ID of the group.
   * @param {module:api/PlayerApi~removeApplicationCallback} callback The callback function, accepting three arguments: error, data, response
   */
  removeApplication(gid, callback) {
    let postBody = null;
    // verify the required parameter 'gid' is set
    if (gid === undefined || gid === null) {
      throw new Error("Missing the required parameter 'gid' when calling removeApplication");
    }
    let pathParams = {
      'gid': gid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/player/remove-application/{gid}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the setMain operation.
   * @callback module:api/PlayerApi~setMainCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Character} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Change the main character from the player account.
   * Needs role: user
   * @param {Number} cid Character ID.
   * @param {module:api/PlayerApi~setMainCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Character}
   */
  setMain(cid, callback) {
    let postBody = null;
    // verify the required parameter 'cid' is set
    if (cid === undefined || cid === null) {
      throw new Error("Missing the required parameter 'cid' when calling setMain");
    }
    let pathParams = {
      'cid': cid
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Character.default;
    return this.apiClient.callApi('/user/player/set-main/{cid}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the setStatus operation.
   * @callback module:api/PlayerApi~setStatusCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Change the player's account status.
   * Needs role: user-manager
   * @param {Number} id ID of the player.
   * @param {module:model/String} status The new status.
   * @param {module:api/PlayerApi~setStatusCallback} callback The callback function, accepting three arguments: error, data, response
   */
  setStatus(id, status, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling setStatus");
    }
    // verify the required parameter 'status' is set
    if (status === undefined || status === null) {
      throw new Error("Missing the required parameter 'status' when calling setStatus");
    }
    let pathParams = {
      'id': id,
      'status': status
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/player/{id}/set-status/{status}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the showApplications operation.
   * @callback module:api/PlayerApi~showApplicationsCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/GroupApplication>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Show all group applications.
   * Needs role: user
   * @param {module:api/PlayerApi~showApplicationsCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/GroupApplication>}
   */
  showApplications(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_GroupApplication.default];
    return this.apiClient.callApi('/user/player/show-applications', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the showById operation.
   * @callback module:api/PlayerApi~showByIdCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Player} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Show all data from a player.
   * Needs role: user-admin, user-manager
   * @param {Number} id ID of the player.
   * @param {module:api/PlayerApi~showByIdCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Player}
   */
  showById(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling showById");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Player.default;
    return this.apiClient.callApi('/user/player/{id}/show', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userPlayerAddRole operation.
   * @callback module:api/PlayerApi~userPlayerAddRoleCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Add a role to the player.
   * Needs role: user-admin
   * @param {Number} id ID of the player.
   * @param {module:model/String} name Name of the role.
   * @param {module:api/PlayerApi~userPlayerAddRoleCallback} callback The callback function, accepting three arguments: error, data, response
   */
  userPlayerAddRole(id, name, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userPlayerAddRole");
    }
    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling userPlayerAddRole");
    }
    let pathParams = {
      'id': id,
      'name': name
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/player/{id}/add-role/{name}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userPlayerCharacters operation.
   * @callback module:api/PlayerApi~userPlayerCharactersCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Player} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Show player with characters, moved characters, groups and service accounts.
   * Needs role: app-admin, group-admin, user-manager, user-chars, watchlist, tracking.<br>If a user only has the tracking or watchlist roles, the player must have a character in a corporation for which the user has access to the member tracking data or the player must be on a watchlist that the user can view.
   * @param {Number} id ID of the player.
   * @param {module:api/PlayerApi~userPlayerCharactersCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Player}
   */
  userPlayerCharacters(id, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userPlayerCharacters");
    }
    let pathParams = {
      'id': id
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Player.default;
    return this.apiClient.callApi('/user/player/{id}/characters', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userPlayerRemoveRole operation.
   * @callback module:api/PlayerApi~userPlayerRemoveRoleCallback
   * @param {String} error Error message, if any.
   * @param data This operation does not return a value.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Remove a role from a player.
   * Needs role: user-admin
   * @param {Number} id ID of the player.
   * @param {module:model/String} name Name of the role.
   * @param {module:api/PlayerApi~userPlayerRemoveRoleCallback} callback The callback function, accepting three arguments: error, data, response
   */
  userPlayerRemoveRole(id, name, callback) {
    let postBody = null;
    // verify the required parameter 'id' is set
    if (id === undefined || id === null) {
      throw new Error("Missing the required parameter 'id' when calling userPlayerRemoveRole");
    }
    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling userPlayerRemoveRole");
    }
    let pathParams = {
      'id': id,
      'name': name
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['CSRF', 'Session'];
    let contentTypes = [];
    let accepts = [];
    let returnType = null;
    return this.apiClient.callApi('/user/player/{id}/remove-role/{name}', 'PUT', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the userPlayerShow operation.
   * @callback module:api/PlayerApi~userPlayerShowCallback
   * @param {String} error Error message, if any.
   * @param {module:model/Player} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Return the logged-in player with all properties.
   * Needs role: user
   * @param {module:api/PlayerApi~userPlayerShowCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/Player}
   */
  userPlayerShow(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = _Player.default;
    return this.apiClient.callApi('/user/player/show', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the withCharacters operation.
   * @callback module:api/PlayerApi~withCharactersCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Player>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all players with characters.
   * Needs role: user-admin
   * @param {module:api/PlayerApi~withCharactersCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Player>}
   */
  withCharacters(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Player.default];
    return this.apiClient.callApi('/user/player/with-characters', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the withRole operation.
   * @callback module:api/PlayerApi~withRoleCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Player>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all players with a role.
   * Needs role: user-admin
   * @param {module:model/String} name Role name.
   * @param {module:api/PlayerApi~withRoleCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Player>}
   */
  withRole(name, callback) {
    let postBody = null;
    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling withRole");
    }
    let pathParams = {
      'name': name
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Player.default];
    return this.apiClient.callApi('/user/player/with-role/{name}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the withStatus operation.
   * @callback module:api/PlayerApi~withStatusCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Player>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * Lists all players with characters who have a certain status.
   * Needs role: user-admin, user-manager
   * @param {module:model/String} name Status name.
   * @param {module:api/PlayerApi~withStatusCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Player>}
   */
  withStatus(name, callback) {
    let postBody = null;
    // verify the required parameter 'name' is set
    if (name === undefined || name === null) {
      throw new Error("Missing the required parameter 'name' when calling withStatus");
    }
    let pathParams = {
      'name': name
    };
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Player.default];
    return this.apiClient.callApi('/user/player/with-status/{name}', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }

  /**
   * Callback function to receive the result of the withoutCharacters operation.
   * @callback module:api/PlayerApi~withoutCharactersCallback
   * @param {String} error Error message, if any.
   * @param {Array.<module:model/Player>} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * List all players without characters.
   * Needs role: user-admin
   * @param {module:api/PlayerApi~withoutCharactersCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link Array.<module:model/Player>}
   */
  withoutCharacters(callback) {
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};
    let authNames = ['Session'];
    let contentTypes = [];
    let accepts = ['application/json'];
    let returnType = [_Player.default];
    return this.apiClient.callApi('/user/player/without-characters', 'GET', pathParams, queryParams, headerParams, formParams, postBody, authNames, contentTypes, accepts, returnType, null, callback);
  }
}
exports.default = PlayerApi;