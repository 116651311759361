"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ApiClient = _interopRequireDefault(require("../ApiClient"));
var _Character = _interopRequireDefault(require("./Character"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * Neucore API
 * Client library of Neucore API
 *
 * The version of the OpenAPI document: 2.7.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

/**
 * The EsiToken model module.
 * @module model/EsiToken
 * @version 2.7.2
 */
class EsiToken {
  /**
   * Constructs a new <code>EsiToken</code>.
   * @alias module:model/EsiToken
   * @param eveLoginId {Number} ID of EveLogin
   * @param characterId {Number} ID of Character
   * @param playerId {Number} ID of Player
   * @param validToken {Boolean} Shows if the refresh token is valid or not.  This is null if there is no refresh token (EVE SSOv1 only) or a valid token but without scopes (SSOv2).
   * @param validTokenTime {Date} Date and time when the valid token property was last changed.
   * @param hasRoles {Boolean} Shows if the EVE character has all required roles for the login.  Null if the login does not require any roles or if the token is invalid.
   */
  constructor(eveLoginId, characterId, playerId, validToken, validTokenTime, hasRoles) {
    EsiToken.initialize(this, eveLoginId, characterId, playerId, validToken, validTokenTime, hasRoles);
  }

  /**
   * Initializes the fields of this object.
   * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
   * Only for internal use.
   */
  static initialize(obj, eveLoginId, characterId, playerId, validToken, validTokenTime, hasRoles) {
    obj['eveLoginId'] = eveLoginId;
    obj['characterId'] = characterId;
    obj['playerId'] = playerId;
    obj['validToken'] = validToken;
    obj['validTokenTime'] = validTokenTime;
    obj['hasRoles'] = hasRoles;
  }

  /**
   * Constructs a <code>EsiToken</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EsiToken} obj Optional instance to populate.
   * @return {module:model/EsiToken} The populated <code>EsiToken</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EsiToken();
      if (data.hasOwnProperty('eveLoginId')) {
        obj['eveLoginId'] = _ApiClient.default.convertToType(data['eveLoginId'], 'Number');
      }
      if (data.hasOwnProperty('characterId')) {
        obj['characterId'] = _ApiClient.default.convertToType(data['characterId'], 'Number');
      }
      if (data.hasOwnProperty('playerId')) {
        obj['playerId'] = _ApiClient.default.convertToType(data['playerId'], 'Number');
      }
      if (data.hasOwnProperty('playerName')) {
        obj['playerName'] = _ApiClient.default.convertToType(data['playerName'], 'String');
      }
      if (data.hasOwnProperty('character')) {
        obj['character'] = _Character.default.constructFromObject(data['character']);
      }
      if (data.hasOwnProperty('validToken')) {
        obj['validToken'] = _ApiClient.default.convertToType(data['validToken'], 'Boolean');
      }
      if (data.hasOwnProperty('validTokenTime')) {
        obj['validTokenTime'] = _ApiClient.default.convertToType(data['validTokenTime'], 'Date');
      }
      if (data.hasOwnProperty('hasRoles')) {
        obj['hasRoles'] = _ApiClient.default.convertToType(data['hasRoles'], 'Boolean');
      }
      if (data.hasOwnProperty('lastChecked')) {
        obj['lastChecked'] = _ApiClient.default.convertToType(data['lastChecked'], 'Date');
      }
    }
    return obj;
  }

  /**
   * Validates the JSON data with respect to <code>EsiToken</code>.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @return {boolean} to indicate whether the JSON data is valid with respect to <code>EsiToken</code>.
   */
  static validateJSON(data) {
    // check to make sure all required properties are present in the JSON string
    for (const property of EsiToken.RequiredProperties) {
      if (!data.hasOwnProperty(property)) {
        throw new Error("The required field `" + property + "` is not found in the JSON data: " + JSON.stringify(data));
      }
    }
    // ensure the json data is a string
    if (data['playerName'] && !(typeof data['playerName'] === 'string' || data['playerName'] instanceof String)) {
      throw new Error("Expected the field `playerName` to be a primitive type in the JSON string but got " + data['playerName']);
    }
    // validate the optional field `character`
    if (data['character']) {
      // data not null
      _Character.default.validateJSON(data['character']);
    }
    return true;
  }
}
EsiToken.RequiredProperties = ["eveLoginId", "characterId", "playerId", "validToken", "validTokenTime", "hasRoles"];

/**
 * ID of EveLogin
 * @member {Number} eveLoginId
 */
EsiToken.prototype['eveLoginId'] = undefined;

/**
 * ID of Character
 * @member {Number} characterId
 */
EsiToken.prototype['characterId'] = undefined;

/**
 * ID of Player
 * @member {Number} playerId
 */
EsiToken.prototype['playerId'] = undefined;

/**
 * Name of Player
 * @member {String} playerName
 */
EsiToken.prototype['playerName'] = undefined;

/**
 * @member {module:model/Character} character
 */
EsiToken.prototype['character'] = undefined;

/**
 * Shows if the refresh token is valid or not.  This is null if there is no refresh token (EVE SSOv1 only) or a valid token but without scopes (SSOv2).
 * @member {Boolean} validToken
 */
EsiToken.prototype['validToken'] = undefined;

/**
 * Date and time when the valid token property was last changed.
 * @member {Date} validTokenTime
 */
EsiToken.prototype['validTokenTime'] = undefined;

/**
 * Shows if the EVE character has all required roles for the login.  Null if the login does not require any roles or if the token is invalid.
 * @member {Boolean} hasRoles
 */
EsiToken.prototype['hasRoles'] = undefined;

/**
 * When the refresh token was last checked for validity.
 * @member {Date} lastChecked
 */
EsiToken.prototype['lastChecked'] = undefined;
var _default = exports.default = EsiToken;